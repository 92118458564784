import Keycloak from 'keycloak-js';
const keycloakConfig = 
	window.location.hostname.includes("cloud.irrigacion.gov.ar")?
	{
		url:"https://auth.cloud.irrigacion.gov.ar/auth",
		realm: 'dgi_kc',
		clientId: 'web_app',
		onLoad: 'login-required',
		logoutUrl:
			"https://auth.cloud.irrigacion.gov.ar/auth" +
			'/realms/' +
			'dgi_kc' +
			'/protocol/openid-connect/logout?redirect_uri=',
	}:
	window.location.hostname.includes("test.di.dgi.loc")?
	{
		url:'http://app.test.di.dgi.loc:9080/auth',
		realm: 'jhipster',
		clientId: 'web_app',
		onLoad: 'login-required',
		logoutUrl:
			'http://app.test.di.dgi.loc:9080/auth' +
			'/realms/' +
			'jhipster' +
			'/protocol/openid-connect/logout?redirect_uri=',
	}
	:{
		url: process.env.REACT_APP_KEYCLOAK,
		realm: process.env.REACT_APP_KEYCLOAK_REALM,
		clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
		onLoad: process.env.REACT_APP_KEYCLOAK_ON_LOAD,
		logoutUrl:
			process.env.REACT_APP_KEYCLOAK +
			'/realms/' +
			process.env.REACT_APP_KEYCLOAK_REALM +
			'/protocol/openid-connect/logout?redirect_uri=',
	}
;

const keycloak = Keycloak(keycloakConfig);

export default keycloak;