import axios from "axios";

export const ACTION_TYPES = {
  COORDINAR_USUARIO: "user/COORDINAR_USUARIO",
  SET_PERSONA_USUARIOPORTAL:"SET_PERSONA_USUARIOPORTAL",
};

const initialState = {
  usuario: null,
};

// Reducer

export default function servicioStates (state = initialState, action) {
  switch (action.type) {
    

    case ACTION_TYPES.COORDINAR_USUARIO: {
      return {
        ...state,
        usuario: action.payload.data,
      }
    }
    case ACTION_TYPES.SET_PERSONA_USUARIOPORTAL: {
      return {
        ...state,
        persona: action.payload.data[0],
      }
    }

    default:
      return state;
  }
}

const apiUrl = "/services/serviciosweb/api/usuario-portals";
const apiUrl3 = "/services/presupuesto/api/private-usuario"
// Actions

export const coordinarUsuario = () => async (dispatch) => {
  try {
    
    let res = await axios.post(apiUrl + '/coordinar');
    dispatch({ type: ACTION_TYPES.COORDINAR_USUARIO, payload: res });
    if (res.data.codigoPersona){
      res=await axios.get(apiUrl3+"/personas",{params:{"codigo.equals":res.data.codigoPersona}});
      dispatch({ type: ACTION_TYPES.SET_PERSONA_USUARIOPORTAL, payload: res });
    }
  } catch (error) {
    throw new Error(error);
  }
};
const apiUrl2 = "/services/presupuesto/api/servicios";
export const traerServiciosAdminisitrables= async (codigoPersona,page,size) => {
  try {
    let res = await axios.get(apiUrl2,{params:{"codigo_persona.equals":codigoPersona,page,size}});
    return {
      data:res.data,
      currentPage:page,
      totalPages:Math.ceil(res.headers["x-total-count"]/size)
    };
  } catch (error) {
    
    throw new Error(error);
  }
};
export const setUser = (user) => async dispatch => {
  try {
    localStorage.setItem("grupos-permisos", user.groups);
    
  }
  catch(e){
    console.error(e);
  }
};





