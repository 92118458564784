import axios from "axios";
import {SET_ERROR_LIGHT} from "./errorReducer";


//Redux maneja el estado de tu aplicaion El reducer lo que hace es reemplazar/sustituir el estado del store cuando se realiza un cambio
export const ACTION_TYPES = {
  FETCH_CTACTE_LIST: "ctacte/FETCH_CTACTE_LIST", // esta action es el tipo de evento que se va a hacer
};

const initialState = {
  ctacteDTO: null,
  lstCtacte: [],
  ctacte: [],
  errores: {},
};

// Reducer

export default function (state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_CTACTE_LIST: { // esta action es el tipo de evento que se va a hacer
      return {
        ...state,
        ctacteDTO: action.payload.data, // se hace un payload para enviar info y que se haga un cambio
      };
    }

    default:
      return state;
  }
}

const apiUrl = "/services/presupuesto/api/cuenta-corrientes"; // Api a la que apuntamos

const apiUrlIG = "/services/ifinfogov/api/private-usuario/ctacte"; // Api a la que apuntamos

//const apiUrlIG = "/services/ifinfogov/api/ctacte";

// Actions

export const deudaAtencionAlPublico = (servicio) => async (
  dispatch
) => {
  try {

    var fechaReferencia = new Date().toISOString().split("T")[0];
    var codigo = servicio;
    var tipoServicio = servicio[0];

    var res = await axios.get(
      `${apiUrlIG}/deudaAtencion?codigo=${codigo}&tipoServicio=${tipoServicio}&fechaVencimiento=${fechaReferencia}`
    );
    res.data.totalDeuda = 0;
    res.data.eventual = 0;
    for (const actual of res.data.ctacteDTODetalles) {
      res.data.totalDeuda += actual.total

      if (!actual.periodo) continue
      const [mes, anio] = actual.periodo.split("/")
      if (anio < new Date().getUTCFullYear()) {
        res.data.totalDeuda += actual.eventuales
        res.data.eventual += actual.eventuales
      }
    }
    dispatch({ type: ACTION_TYPES.FETCH_CTACTE_LIST, payload: res }); //el dispatch envia y recibe cambios que queremos generar en el store
    return res.data;
  }
  catch (error) {
    if (error.response) {
      dispatch({ type: "SET_ERROR", payload: error.response });
    } 
      throw error
    
  }
};

