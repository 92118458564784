import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// import Modal from "react-bootstrap/Modal";

import "./style.css"
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';


export const SET_ERROR = "SET_ERROR";
export const SET_ERROR_LIGHT = "SET_ERROR_LIGHT";
export const HIDE_ERROR = "HIDE_ERROR";
export const SET_WARNING = "SET_WARNING";
export const SET_WARNING_LIGHT = "SET_WARNING_LIGHT";
export const INITIALIZE_ERROR = 'INITIALIZE_ERROR';
export const SET_SUCCESS_LIGHT = "SET_SUCCESS_LIGHT"



function createTitleAndDetail(errorState,dispatch){
  let error=errorState.error;
  if (error.status==500) return {title:"Error",detail:"Fallo Internerno del Servidor"}
  if (error.status==403) return {title:"Error", detail:"Acceso no permitido"}
  if (error && Object.getPrototypeOf(error).toString()=='[object Blob]'){
    try {
      error.text().then(datatext=>dispatch({type:errorState.type,payload:{...JSON.parse(datatext)}}));
      return {};
    }
    catch(e){
      return {title:"Error",detail:"desconocido"}
    }
    
  }
  if (error.status==400) {
    if (error.error=="invalid_grant")return {title:"Usuario Invalido",detail:"Usuario o contraseña incorrecto"}
    return {title:"Error en la consulta", detail:"No ha completado bien los datos requeridos"}
  }
  if (error.status==401){
    return {title:"Sesión caducada",detail:"Se ha vencido la sesion, por favor, inicie sesion de nuevo"}
  }
  if (error.status==413){
    return {title:"La solicitud es muy pesada",detail:"Avise al administrador, codigo http 413"}
  }
  if (error.error=="invalid_grant" || error.error=="unauthorized_client"){
    return {title:"Falló Login",detail:"Usuario o contraseña incorrectos"}
  }
  if (!error.response){
    return {title:"Sin Internet",detail:"No hay conexion"}
  }
  return {title:"error",detail:"error"}
}
const ErrorNotification = (props) => {
  let errorState = useSelector(state => state.errorReducer);
  const [errorLightStep,setErrorLightStep]=useState(-1);
  const [state]=useState({thread:0,errorState:null});
  let titulo = '';
  let mensaje = '';
  const dispatch=useDispatch();
  const history = useHistory();
  let tipoMensaje = 'error';
  if (errorState.error && !errorState.error['title'] ){
    const result=createTitleAndDetail(errorState,dispatch);
    if (!result)return null;
    titulo=result.title;
    mensaje=result.detail;
  }
  else if (errorState.error){
    titulo=errorState.error.title;
    mensaje=errorState.error.detail;
  }
  if (errorState.type==SET_ERROR && errorState!=state.errorState) { 
    state.errorState=errorState;
    Swal.fire({
      icon: tipoMensaje,
      title: titulo,
      text: mensaje,
    }).then(() => {
      if (props.redirectOnDismiss && parseInt(errorState.status || errorState.error.status) === 403) {
        history.replace(props.redirectOnDismiss)
      }
    });
  }
  
  if (state.errorState!=errorState && [SET_ERROR_LIGHT,SET_SUCCESS_LIGHT].includes(errorState.type)){
    state.errorState=errorState;
    console.log(state)
    setErrorLightStep(0);
  }                           

    
    
  if ( errorState.error && [SET_ERROR_LIGHT,SET_SUCCESS_LIGHT].includes(errorState.type) ) {
      let classNameBackground= SET_ERROR_LIGHT==errorState.type? "error_background" : "success_background";
      let className="";
      if (errorLightStep==0){
        const thread=state.thread=setTimeout(()=>thread==state.thread && setErrorLightStep(1),200)
      }
      if (errorLightStep==1){
        className="utilLightErrorNotification_visible";
        const thread=state.thread=setTimeout(()=>thread==state.thread && setErrorLightStep(2),5000)
      }
      else if (errorLightStep==2){
          setErrorLightStep(-1);
      }
    
      return (
        <div className={classNameBackground+ " utilLightErrorNotification "+className}>
          <b>{titulo}:</b>{mensaje}
        </div>
      )
  }
  else return <div></div>;
}

export default ErrorNotification;