import Swal from "sweetalert2";

// errorTypes.js
export const SET_ERROR = "SET_ERROR";
export const SET_ERROR_LIGHT = "SET_ERROR_LIGHT";
export const HIDE_ERROR = "HIDE_ERROR";
export const SET_WARNING = "SET_WARNING";
export const SET_WARNING_LIGHT = "SET_WARNING_LIGHT";
export const INITIALIZE_ERROR = 'INITIALIZE_ERROR';
export const SET_SUCCESS_LIGHT = 'SET_SUCCESS_LIGHT';


// errorReducer.js
const initState = {
  error: null,
  type:null,
 };
 
 export default function errorReducer(state = initState, action){
 
  switch (action.type) {
     case SET_ERROR: {
       return {
         error: action.payload?.data || action.payload,
         status: action.payload?.data.status || action.payload.status, 
         type:SET_ERROR
         
       };
     }
 
     case SET_ERROR_LIGHT: {
       return {
         error: action.payload?.data || action.payload,
         status: action.payload?.data.status || action.payload.status, 
         type:SET_ERROR_LIGHT,
       };
     }
 
     case SET_WARNING: {
       return {
         error: action.payload.data,
         
       };
     }
 
     case SET_WARNING_LIGHT: {
       return {
         error: action.payload.data,
         
       };
     }
     case SET_SUCCESS_LIGHT: {
      return {
          error: {status:200,...action.payload},
          type:SET_SUCCESS_LIGHT,
      };
    }
     case INITIALIZE_ERROR: {
       return {
         error: null,
         type:null,
         
       };
     }
     default:
       return state;
 
  }
 
 }