import { combineReducers } from "redux";
import { reducer as reduxForm } from "redux-form";
import servicioAsociadoReducer from "./servicioAsociadoReducer";
import tipoServicioReducer from "./tipoServicioReducer";
import servicioReducer from './servicioReducer';
import ventanillaElectronicaReducer from './ventanillaElectronicaReducer';
import cuotaReducer from './cuotaReducer';
import ctaCteReducer from './cuentaCorrienteReducer';
import userReducer from "./userReducer"
import personaReducer from './personaReducer';
import errorReducer from "./errorReducer";
import actividadReducer from "./actividadReducer";
export default combineReducers({
    form: reduxForm,
    servicioAsociadoReducer,
    tipoServicioReducer,
    servicioReducer,
    ventanillaElectronicaReducer,
    cuotaReducer,
    personaReducer,
    ctaCteReducer,
    userReducer,
    errorReducer,
    actividadReducer,
  });