import axios from "axios";
import { SubmissionError } from "redux-form";
import { objToQuery } from "../util/lib";
import { SET_ERROR_LIGHT,  SET_ERROR} from "./errorReducer";
const reducerName="personaReducer"

export const tiposPersona = {
  BUSCAR_PERSONA_POR_CUIT: "BUSCAR_PERSONA_POR_CUIT",
};

const INITIAL_STATE = {
  listaPersona: [],
  persona: {},
  cargando: false,
  errores: {},
};

//Reducer

export default function personaState(state = INITIAL_STATE, action) {
  switch (action.type) {
    case tiposPersona.BUSCAR_PERSONA_POR_CUIT: {
      return {
        ...state,
        persona: action.payload.data,
        cargando: true,
      };
    }

    default:
      return state;
  }
}

const apiUrl = "/services/presupuesto/api/private-usuario/personas";

//Acciones

export const buscarPersonaPorCuit = (cuit) => async (dispatch) => {
  try {
    const res = await axios.get(`${apiUrl}/findByCuit/${cuit}`);
    return dispatch({
      type: tiposPersona.BUSCAR_PERSONA_POR_CUIT,
      payload: res,
    });
  } catch (error) {
    dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
    throw new SubmissionError(error);
  }
};

export const buscarPersonas = (page,size,filters) => async (dispatch) => {
  try {
    const obj = objToQuery({
      page: page,
      size: size,
      ...filters,
    });
    const res = await axios.get(apiUrl + obj);
    let cantTotal = parseInt(res.headers["x-total-count"]);
    return {
      personas: res.data,
      currentPage: page,
      totalPages: Math.ceil(cantTotal/size),
    }
      
  } catch (error) {
    dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
  }
};
const apiUrl2 = "/services/serviciosweb/api/usuario-externo/validar-usuarios";
export const continuarValidacion = (usuario) => async (dispatch) => {
  try {
    
    const result=await axios.get(`${apiUrl2}/continuarValidacion/${usuario.id}`);
    return ordenarDatosValidacion( result.data);
  }
  catch (error){
    if (error.response && error.response.status!==404 )dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
    throw error;
  }
};
export const estadoValidacion = (id) => async (dispatch) => {
  try {
    const result=await axios.get(`${apiUrl2}/getValidacionUsuario`);
    return result.data;
  }
  catch (error){
    if (error.response && error.response.status!==404 )dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
    throw error;
  }
};

const ordenarDatosValidacion=(form)=>{
  if (form.validarDatos) form.validarDatos=form.validarDatos.sort((a,b)=>a.id-b.id);
  return form; 
}
export const tipoJuridica="juridica";
export const tipoFisica="fisica";
export const tipoSucesion="sucesion";
export const iniciarValidacion = (usuario,tipoPersona) => async (dispatch) => {
  try {
    const result=await axios.post(`${apiUrl2}/iniciarValidacion/${usuario.id}/persona${tipoPersona}`);
    return ordenarDatosValidacion(result.data);
  }
  catch (error){
    if (error.response)dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
    throw error;
  }
};
export const borrarValidacion = (validacion) => async (dispatch) => {
  try {
 
    const result=await axios.delete(`${apiUrl2}/eliminarValidacionUsuario`,{data:validacion});
    return result.data;
  }
  catch (error){
    if (error.response)dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
    throw error;
  }
};

export const guardarDatosPersona = (values) => async (dispatch) => {
  try {
    
    const result=await axios.post(`${apiUrl2}/guardarValidarUsuario`,values);
    return result.data;
  }
  catch (error){
    if (error.response)dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
    throw error;
  }
};
export const validarPersona = (values) => async (dispatch) => {
  try {
    const result=await axios.post(`${apiUrl2}/confirmarValidarUsuario`,values);
    return result.data;
  }
  catch (error){
    if (error.response)dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
    throw error;
  }
};
export const subirArchivo = (fd,string) => async (dispatch) => {
  try {
    const form = new FormData();
    form.append("file",fd,fd.name)
    const result= await axios.post("/services/serviciosweb/api/usuario-externo/archivos/agregarArchivo/"+string,form,
    {headers: {
      'Content-Type': 'multipart/form-data'
    }})
    return result.data;
  }
  catch(error){
    if (error.response)dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
    throw error;
  }
};
export const eliminarArchivo = (string) => async (dispatch) => {
  try {   
    await axios.post("/services/serviciosweb/api/usuario-externo/archivos/quitarArchivo/"+string);    
  }
  catch(error){
    if (error.response)dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
    throw error;
  }
};
const apiUrl4 = "/services/serviciosweb/api/usuario-portals";
export const buscarUsuariosPortal = (page,size,searchProps,withOutDispatch) => async (dispatch) => {
    const obj = objToQuery({
        page: page,
        size: size,
        ...searchProps,
    })
    try {
        const res=await axios.get(`${apiUrl4}${obj}`)
        let cantTotal = parseInt(res.headers["x-total-count"]);
        const result=  {
            personas:res.data,
            currentPage: page,
            totalPages: Math.ceil(cantTotal / size),
        }; 
        if (withOutDispatch){
            return result
        }
        else {
            dispatch({
                type: reducerName,
                payload:result,
                fn: (state,action) => ({
                    ...state,
                    ...action.payload
                })
            })   
        }
    }  
    catch(error){
        if (error.response){ 
            dispatch({ type: SET_ERROR, payload: error.response });
        }
        throw error;
    }
}



